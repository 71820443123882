.badge-emojis {
  .badge-emoji-add {
    form {
      @apply transition-all;
      z-index: -1;
    }
    &:not(.open) {
      form {
        @apply absolute right-2 opacity-0;
        button {
          @apply hidden;
        }
      }
    }
    &.open {
      @apply shadow-lg;
      .add-icon {
        @apply hidden;
      }
      form {
        z-index: 0;
        &:not(:last-of-type) {
          @apply mr-2;
        }
      }
    }
  }
}
