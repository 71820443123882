body.users_onboarding_discoveries#new {
  .discovery-box {
    position: relative;
    textarea {
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
      }
    }
    &:not(:first-of-type) {
      &:before {
        content: ' ';
        height: calc(2.5rem + 1px);
        width: 5px;
        position: absolute;
        top: calc(-2.5rem - 1px);
        @apply bg-gray-200;
        left: calc(50% - 2.5px);
      }
    }
    &:last-child {
      margin-bottom: 5rem;
      &:after {
        content: ' ';
        height: calc(5rem + 1px);
        width: 5px;
        position: absolute;
        bottom: calc(-5rem - 1px);
        @apply bg-gradient-to-b from-gray-200 to-orange-500;
        left: calc(50% - 2.5px);
      }
    }
  }
}
