.reward-program-reward-types {
  .reward-program-reward-types-control {
    .reward-type {
      @apply text-sm overflow-hidden;
      .inputs {
        @apply duration-100 transition-all overflow-y-hidden;
      }
      @apply border-l border-r border-b;
      &:not(.active) {
        .inputs {
          max-height: 0px;
        }
      }

      &.active {
        .inputs {
          // max-height: 230px;
          @apply bg-white pt-5;
        }
        .summary {
          @apply bg-gray-50;
          &:hover {
            .close {
              @apply visible;
            }
          }
        }
      }

      &:not(.active) {
        .summary {
          &:hover {
            @apply bg-white;
          }
        }
      }

      .summary {
        @apply cursor-pointer;
        .close, .remove {
          @apply invisible;
        }
        &:hover {
          .remove {
            @apply visible;
          }
        }
      }
      &:first-of-type {
        @apply border-t rounded-t-md;
      }
      &:last-of-type {
        @apply border-b rounded-b-md;
      }
    }
  }
}
