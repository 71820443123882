#onboarding-landing {
  #connector-0 {
    height: calc(75% - 92px);
    width: 5px;
    position: absolute;
    top: 90px;
    @apply bg-gradient-to-b from-orange-500 to-pink-500;
    left: calc(50% - 2.5px);
    @apply lg:hidden;
  }
  #connector-1 {
    height: calc(25% - 46px);
    width: 5px;
    position: absolute;
    top: 46px;
    @apply bg-gradient-to-b from-orange-500 to-pink-500;
    left: calc(25% - 2.5px);
    @apply hidden lg:block;
  }
  #connector-2 {
    width: 15%;
    height: 25%;
    border-width: 5px;
    border-color: transparent transparent #EC4899 #EC4899;
    border-radius: 0px 0px 0px 50%;
    position: absolute;
    border-top: none;
    top: 25%;
    left: calc(25% - 2.5px);
    @apply hidden lg:block;
  }
  #connector-3 {
    width: 15%;
    height: 25%;
    border-width: 5px;
    border-color: #EC4899 #EC4899 transparent transparent;
    border-radius: 0px 50% 0px 0px;
    position: absolute;
    border-bottom: none;
    top: calc(50% - 5px);
    right: calc(50% - 2.5px);
    @apply hidden lg:block;
  }
  #connector-4 {
    height: calc(25% + 5px);
    width: 5px;
    position: absolute;
    top: calc(75% - 5px);
    @apply bg-gradient-to-b from-pink-500 to-gray-200;
    left: calc(50% - 2.5px);
  }
  #stepper {
    .step {
      &:not(:first-child) {
        &:after {
          content: ' ';
          width: calc(100% - 1rem);
          height: 1px;
          @apply bg-gray-200;
          position: absolute;
          top: 8px;
          left: calc(-50% + 0.5rem);
        }
      }
    }
  }
}
