#new-assessment {
  label {
    &.r1 {
      @apply bg-red-100 border-red-100;
    }
    &.r2 {
      @apply bg-orange-100 border-orange-100;
    }
    &.r3 {
      @apply bg-yellow-100 border-yellow-100;
    }
    &.r4 {
      @apply bg-lime-100 border-lime-100;
    }
    &.r5 {
      @apply bg-green-100 border-green-100;
    }
  }
  .active {
    label {
      &.r1 {
        @apply bg-red-500 border-red-500 ring-2 ring-red-500 ring-offset-2;
      }
      &.r2 {
        @apply bg-orange-500 border-orange-500 ring-2 ring-orange-500 ring-offset-2;
      }
      &.r3 {
        @apply bg-yellow-500 border-yellow-500 ring-2 ring-yellow-500 ring-offset-2;
      }
      &.r4 {
        @apply bg-lime-500 border-lime-500 ring-2 ring-lime-500 ring-offset-2;
      }
      &.r5 {
        @apply bg-green-500 border-green-500 ring-2 ring-green-500 ring-offset-2;
      }
    }
  }
}
