@import 'styles';

body.email {
  background-color: #f9fafb;
  padding: 1.25rem;
  a {
    color: #ff9800;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .border-gray {
    border-color: #e5e7eb;
  }
}
