@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@import 'trix/dist/trix.css';
@tailwind utilities;

@import "styles";

body {
  font-family: "Ubuntu";
  line-height: 135%;
  font-smoothing: antialiased;
  @apply text-gray-700;
  &.app {
    @apply lg:ml-20 lg:mt-20 pb-3;
    @apply bg-gray-100 lg:bg-gray-50;
    #app-main {
      @apply py-5 lg:px-5;
    }
  }
}

a {
  @apply text-orange;
  -webkit-tap-highlight-color: transparent;
  &[disabled] {
    @apply text-gray-300 cursor-not-allowed;
  }
}

button {
  -webkit-tap-highlight-color: transparent;
  font-family: "Ubuntu" !important;
}

input, select, textarea, optgroup {
  font-family: "Ubuntu" !important;
}

h1, h2, h3, h4, h5, h6 {
  @apply text-black font-bold;
}

hr {
  @apply border-gray-200;
}

.text {
  @apply text-gray-700;
}

.turbo-progress-bar {
  @apply bg-orange;
}
