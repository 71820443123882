#goal-form-step-1 {
  .radiobox {
    &.active {
      label {
        .adverb {
          @apply text-orange-200;
        }
        .category {
          @apply text-orange;
        }
      }
    }
    &:not(.active) {
      label {
        @apply border-transparent;
        .adverb {
          @apply text-gray-500;
        }
        .category {
          @apply text-black;
        }
      }
    }
  }
}
