.spinner {
  display: inline-block;
  position: relative;
  @apply h-10 w-10;
}
.spinner div {
  @apply h-8 w-8 border-4 border-gray-200;
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #e5e7eb transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
