.sheet {
  @apply z-0 border border-gray-200;
  .handsontable {
    font-family: 'Ubuntu';
    tr {
      &:first-child {
        td {
          border-color: #e5e7eb;
          border-left: none;
        }
        th {
          border-color: #e5e7eb;
          border-left: none;
          border-top: none;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
    td {
      border-color: #e5e7eb;
      &:first-of-type {
        border-left: none;
      }
      &:last-child {
        border-right: none;
      }
      &.htInvalid {
        background-color: #ef4444 !important;
      }
      &.read-only {
        background-color: #f9fafb;
        color: #6b7280;
      }
      .htCheckboxRendererInput {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        display: flex;
      }
    }
    th {
      background-color: #f9fafb;
      border-color: #e5e7eb;
      font-weight: bold;
      text-transform: uppercase;
      &:last-child {
        border-right: none;
      }
      .relative {
        padding: 4px;
      }
      &:last-child {
        border-color: #e5e7eb;
      }
      &:nth-child(2) {
        border-color: #e5e7eb !important;
      }
    }
    tbody {
      tr {
        td {
          padding: 2px 4px;
          line-height: 22px !important;
          @apply text-sm;
          &:not(:first-child) {
            .cH {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .handsontableInput {
    line-height: 26px !important;
  }
}
