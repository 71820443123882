.button {
  @apply focus:outline-none cursor-pointer uppercase inline-flex;
  @apply items-center justify-center space-x-2;
  &[disabled] {
    @apply opacity-50 cursor-not-allowed bg-gray-300 text-gray-100;
  }
  .preceding-text + .material-icons {
    float: right;
  }

  .preceding-text {
    &+ .material-icons-round {
      margin-left: 0.5em;
    }
  }
  &.button-lg {
    @apply px-5 py-3 rounded-md text-sm font-bold;
  }
  &.lg\:button-lg {
    @screen lg {
      @apply px-5 py-3 rounded-md text-sm font-bold #{!important};
    }
  }
  &.button-md {
    @apply px-4 py-2 rounded-md text-xs font-bold;
    .material-icons-round {
      @apply text-xl -my-10;
      &+ .trailing-text {
        margin-left: 0.5em;
      }
    }
  }
  &.button-sm {
    @apply px-3 py-1 rounded-md text-xs font-bold;
    .material-icons-round {
      @apply text-base leading-none;
    }
  }
  &:not([disabled]) {
    &.button-action {
      @apply bg-orange hover:bg-orange-600 text-white border border-orange;
    }
    &.button-black {
      @apply bg-black hover:bg-gray-900 text-white border border-black;
      &.button-outline {
        @apply bg-white border-black text-black hover:bg-gray-50;
      }
    }
    &.button-blue {
      @apply bg-blue-500 hover:bg-blue-600 text-white border border-blue-500;
    }
    &.button-brown {
      @apply bg-brown-900 hover:bg-black text-white border border-brown-900;
      &.button-outline {
        @apply bg-white border-brown-900 text-brown-900 hover:bg-brown-50;
      }
    }
    &.button-gradient {
      @apply bg-gradient-to-r from-orange-500 to-pink-500 text-white;
    }
    &.button-gray {
      @apply bg-gray-400 hover:bg-gray-500 text-white border border-gray-400;
      &.button-outline {
        @apply bg-white border-gray-400 text-gray-400 hover:bg-gray-50;
      }
    }
    &.button-green {
      @apply bg-green-500 hover:bg-green-600 text-white border border-green-500;
    }
    &.button-orange {
      @apply bg-orange hover:bg-orange-600 text-white border border-orange;
      &.button-outline {
        @apply bg-white text-orange hover:bg-orange-50;
      }
    }
    &.button-pink {
      @apply bg-pink-500 hover:bg-pink-600 text-white border border-pink-500;
      &.button-outline {
        @apply bg-white border-pink-500 text-pink-500 hover:bg-pink-50;
      }
    }
    &.button-plain {
      @apply border border-white text-gray-700 bg-white hover:bg-gray-50;
    }
    &.button-red {
      @apply bg-red hover:bg-red-600 text-white border border-red;
      &.button-outline {
        @apply bg-white text-red hover:bg-red-50;
      }
    }
    &.button-yellow {
      @apply bg-yellow-400 hover:bg-yellow-500 text-white border border-yellow-400;
      &.button-outline {
        @apply bg-white border-yellow-400 text-yellow-500 hover:bg-yellow-50;
      }
    }
    &.button-white {
      @apply text-orange bg-white border border-white hover:bg-gray-50 hover:border hover:border-gray-50;
      &.button-outline {
        @apply bg-transparent text-white hover:bg-white hover:bg-opacity-20;
      }
    }
  }
}
