input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::placeholder {
  @apply text-gray-400;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.input-container {
  &:not(.disabled) {
    .input-field {
      @apply text-gray-900;
    }
  }
  input.input-field, select.input-field {
    &:not([type='checkbox']):not([type='radio']) {
      @apply appearance-none relative block w-full px-3 py-1 mb-5 h-8;
      @apply placeholder-gray-500 text-sm shadow-sm;
      @apply rounded border border-gray-200;
      @apply focus:ring-gray-500 focus:outline-none focus:z-10 focus:border-gray-500;
    }
    &[type='checkbox'] {
      @apply rounded border border-gray-300 text-gray-700;
      @apply focus:ring-0 focus:outline-none focus:z-10;
      &:not(:disabled) {
        &:checked {
          @apply bg-orange border-orange;
        }
      }
    }
    &[type='radio'] {
      @apply border border-gray-300 text-gray-700;
      @apply focus:ring-0 focus:outline-none focus:z-10;
    }
  }
  .input-description {
    @apply text-xs text-gray-400;
  }
  &:not(.toggle) {
    .input-description {
      @apply -mt-4;
    }
  }
  &.toggle {
    .input-description {
      @apply mb-5 mt-0;
    }
  }
  .input-error {
    @apply text-red-400 text-xs -mt-4;
  }
  .input-label {
    @apply block text-sm text-gray-700 font-medium py-1 whitespace-nowrap;
  }
  .input-toggle {
    @apply border-2 border-transparent;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange;
    @apply relative inline-flex flex-shrink-0 h-6 w-11;
    @apply rounded-full cursor-pointer transition-colors ease-in-out duration-200;
    &.active {
      @apply bg-orange;
      .indicator {
        @apply translate-x-5;
      }
    }
    &:not(.active) {
      @apply bg-gray-300;
      .indicator {
        @apply translate-x-0;
      }
    }
    .indicator {
      @apply pointer-events-none inline-block h-5 w-5 rounded-full bg-white;
      @apply transform ring-0 transition ease-in-out duration-200 shadow;
    }
  }
  &.disabled {
    .input-field {
      &:not([type="checkbox"]) {
        @apply cursor-not-allowed bg-gray-50;
      }
      &[type="checkbox"] {
        @apply cursor-not-allowed bg-gray-300;
      }
    }
  }
  &.error {
    .input-field {
      @apply focus:border-red-400 focus:ring-red-400 border-red-400;
    }
    .input-description {
      visibility: hidden;
    }
  }
  &.inline-input-container {
    &:not(.hidden) {
      @apply space-y-1 mx-6 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-4 lg:py-5;
      .input-field:not([type="checkbox"]) {
        @apply block w-full text-sm border-gray-200 rounded-md h-8;
        @apply focus:ring-gray-500 focus:border-gray-500;
      }
      .input-label {
        @apply block lg:mt-px lg:pt-2;
        @apply font-medium leading-4 text-gray-700;
      }
      &.selectfield {
        .input-field {
          @apply py-0;
        }
      }
    }
  }
  &.checkbox {
    .input-description {
      @apply mt-0;
    }
    .inline-input-control {
      @apply flex space-x-2 items-center;
      .input-description {
        @apply mt-0;
      }
    }
  }
  &.selectfield {
    .input-field {
      @apply py-0;
    }
    &.has-value {
      .input-field {
        @apply text-gray-400;
      }
    }
  }
  &.textarea {
    textarea {
      @apply border-gray-200 mb-5 rounded-sm px-3 py-1;
      @apply text-sm w-full resize-none shadow-sm;
      @apply focus:ring-gray-500 focus:outline-none focus:z-10 focus:border-gray-500;
    }
    trix-editor {
      @apply bg-white border-gray-200 mb-5 focus:ring-1 focus:ring-gray-500 text-sm;
    }
    &.no-toolbar {
      trix-toolbar {
        display: none;
      }
    }
  }
}

.inline-inputs {
  @apply space-y-6 lg:space-y-0 lg:divide-y lg:divide-gray-100;
  .toggle {
    .input-description {
      @apply mb-0;
    }
  }
}

#slide-out {
  section {
    .inline-input-container {
      @apply mx-0;
    }
  }
}
